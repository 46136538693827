export const environment = {
	production: true,
	useEmulators: false,
	firebase: {
		apiKey: 'AIzaSyBzBj9BC48uj-vz6J5wAJI01f_qCyINcp0',
		authDomain: 'whitfields-firefly-dev.firebaseapp.com',
		databaseURL: 'https://whitfields-firefly-dev.firebaseio.com',
		projectId: 'whitfields-firefly-dev',
		storageBucket: 'whitfields-firefly-dev.appspot.com',
		messagingSenderId: '809237145868',
	},
	product: 'olympus',
	admin: 'https://firefly.dev.whitfields.co.za',
	client: 'dev.whitfields.co.za',
	olympus: 'olympus.dev.whitfields.co.za',
	googleMapAPI: 'AIzaSyCExvjEsmSQbGZZZG9haw4rSEKcfT5mivo',
	port: 8004,
	allowedLoginDomains: ['noldor.co.za', 'noltech.co.za', 'whitfields.co.za'],
	useSentry: false,
	region: 'europe-west1',
};
